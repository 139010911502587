<template>
  <div class="bg-gradient-to-br from-white to-gray-100 rounded-xl shadow-lg p-5 transition-all duration-300 hover:shadow-xl">
    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center space-x-3">
        <div class="p-1.5 rounded-full bg-gray-100">
          <img :src="integration.icon" :alt="integration.name" class="w-7 h-7">
        </div>
        <h3 class="text-xl font-bold text-gray-800">{{ integration.name }}</h3>
      </div>
      <button v-if="integration.connected" @click="$emit('manage', integration.id)" class="text-gray-500 hover:text-gray-700 transition-colors duration-200" aria-label="Manage integration">
        <span class="material-symbols-outlined !text-2xl">settings</span>
      </button>
    </div>
    <p class="text-base text-gray-600 mb-5">{{ integration.description }}</p>
    <div class="flex items-center justify-between">
      <div class="flex items-center space-x-2">
        <span class="w-2 h-2 rounded-full" :class="integration.connected ? 'bg-green-500' : 'bg-gray-300'"></span>
        <span class="text-sm font-medium" :class="integration.connected ? 'text-green-600' : 'text-gray-500'">{{
            integration.connected ? t('settings.components.integrations.connected') : t('settings.components.integrations.disconnected')
          }}</span>
      </div>
      <custom-button @click="$emit('toggle', integration.id)" :is-loading="integration.loading" :is-disabled="integration.loading" :color="integration.connected ? 'primary' : 'secondary'" variation="rounded-full" size="sm" :button-text="integration.connected ? t('settings.components.integrations.disconnect') : t('settings.components.integrations.connect')" :loading-text="t('settings.components.integrations.loading')"/>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/buttons/customButton.vue'

const { t } = useI18n()

defineProps({
  integration: {
    type: Object,
    required: true,
  },
})

defineEmits(['toggle', 'manage'])
</script>