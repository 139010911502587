<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'general'" @change-tab="handleTabChange">
    <template #right-content>
      <custom-button @click="saveTabSettings" :button-text="t('general.save')" color="header" :isLoading="buttonLoading" :loading-text="t('general.saving')" size="sm"/>
    </template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef" @saving="(val) => buttonLoading = val"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import settingsConfiguration from './components/configuration/index.vue'
import generalConfiguration from './components/general/index.vue'
import scheduleSettings from './components/daySelector/index.vue'
import sourceConfiguration from './components/source/index.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import TabNavigation from '@/components/navigation/tabNavigation.vue'
import integrations from '@/views/settings/components/integration/index.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const buttonLoading = ref(false)
const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'general')

const tabs = [
  { name: 'general', label: t('settings.tab.general'), component: markRaw(generalConfiguration) },
  { name: 'schedule', label: t('settings.tab.schedule'), component: markRaw(scheduleSettings) },
  { name: 'settingsConfiguration', label: t('settings.tab.settingsConfiguration'), component: markRaw(settingsConfiguration) },
  { name: 'sourceConfiguration', label: t('settings.tab.sourceConfiguration'), component: markRaw(sourceConfiguration) },
  { name: 'integrations', label: t('settings.tab.integrations'), component: integrations },
]

const currentTabComponent = computed(() => {
  const tab = tabs.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

const saveTabSettings = async () => {
  try {
    if (tabRef.value && typeof tabRef.value.save === 'function') {
      await tabRef.value.save()
    } else {
      console.warn(`Save method not found for tab: ${currentTab.value}`)
    }
  } catch (error) {
    console.error('Error saving settings:', error)
  }
}
</script>