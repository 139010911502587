<template>
  <div class="w-full px-10 text-white h-full items-center justify-center pl-10 text-left relative bg-cover bg-center bg-fixed hidden md:inline-flex before:absolute before:inset-0 before:backdrop-blur-lg">
    <div class="relative z-10 flex flex-col w-11/12">
      <h1 class="text-3xl font-bold text-secondary">
        {{ t('auth.components.information.title') }} </h1>
      <p class="font-Poppins text-sm font-extralight text-gray-900 mt-5">
        {{ t('auth.components.information.description') }} </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const isLoaded = ref(false)

const { t } = useI18n()

onMounted(() => {
  isLoaded.value = true
})
</script>