<template>
  <div class="flex flex-col space-y-4 w-full">
    <!-- Genel Bilgiler -->
    <div class="bg-white p-4 rounded-lg shadow-sm w-full flex items-center justify-center min-h-screen md:min-h-[500px] 2xl:min-h-[700px]">
      <div v-if="assetLoading" class="flex flex-col items-center justify-center space-y-4">
        <div class="loader"></div>
        <p class="font-semibold text-xl">{{ $t('assets.components.create.components.analyse.messages.analyzing') }}</p>
      </div>

      <div v-else-if="error" class="flex flex-col items-center justify-center space-y-4">
        <svg class="error-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <line x1="15" y1="15" x2="35" y2="35" stroke="#e5007e" stroke-width="2"/>
          <line x1="35" y1="15" x2="15" y2="35" stroke="#e5007e" stroke-width="2"/>
        </svg>
        <p class="font-semibold text-xl">{{ $t('assets.components.create.components.analyse.messages.error') }}</p>
      </div>

      <div v-else class="flex flex-col items-center justify-center space-y-4">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
        <p class="font-semibold text-xl">{{ $t('assets.components.create.components.analyse.messages.completed') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'

export default {
  name: 'Analyse',
  data() {
    return {
      error: false,
    }
  },
  computed: {
    ...mapState({
      assetLoading: (state) => state.assetData.loading,
      informationList: (state) => state.assetData.informationList,
      authList: (state) => state.assetData.authList,
      assetId: (state) => state.assetId,
      customerLanguageId: (state) => state.assetData.customerLanguageId,
      introductionList: (state) => state.assetData.introductionList,
      communicationId: (state) => state.assetData.communicationId,
    }),
  },
  methods: {
    ...mapActions(['setAssetLoading']),

    async sendRequest() {
      await this.setAssetLoading(true)

      const isServiceTypeCrawler = this.$store.state.assetServiceType === 2
      const isEdit = this.$route.path.includes('/edit/')
      const id = this.$route.params.id

      let data = isServiceTypeCrawler ? {
        name: this.introductionList.name,
        website: this.introductionList.website,
        languageId: this.introductionList.languageId,
        searchEngineLocations: this.introductionList.searchEngineLocations,
      } : {
        assetId: this.assetId,
        customerLanguageId: this.customerLanguageId,
        aboutUs: this.informationList.aboutUs,
        facebook: this.informationList.facebookUrl,
        instagram: this.informationList.instagramUrl,
        twitter: this.informationList.twitterUrl,
        linkedin: this.informationList.linkedinUrl,
        phone: this.informationList.phone,
        wphone: this.informationList.wphone,
        keywords: this.informationList.keywords,
        languageId: this.introductionList.languageId,
        username: this.authList.username,
        password: this.authList.password,
        authUrl: this.authList.authUrl,
      }

      let url = assets.add
      let method = 'post'
      let requestConfig = { method, url, data }

      if (isEdit && id) {
        url = assets.edit
        method = 'put'
        data.assetId = id
        data.communicationId = this.introductionList.communicationId
        requestConfig = {
          method,
          url,
          data,
        }
      } else if (isServiceTypeCrawler) {
        url = assets.startCrawler
        requestConfig = { method: 'post', url, data }
      }

      try {
        await axios(requestConfig)
        this.$emit('successResponse', true)
        setTimeout(() => {
          this.$router.push({ name: 'assets' })
        }, 2000)
      } catch (error) {
        this.$responseHandlerService.handleError(this.$t('assets.components.create.components.analyse.messages.addError'))
        this.isValid = false
        await this.setAssetLoading(false)
        this.error = true
      } finally {
        await this.setAssetLoading(false)
      }
    },
  },
  async mounted() {
    await this.sendRequest()
  },
}
</script>

<style scoped>
/* Loading */
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #006eb7;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinAndPulse 2s linear infinite;
}

@keyframes spinAndPulse {
  0% {
    transform: scale(1) rotate(0deg);
  }

  50% {
    transform: scale(1.1) rotate(180deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

/* Success */
.checkmark {
  width: 120px;
  height: 120px;
  animation: scale 0.3s forwards;
}

.checkmark__circle {
  stroke: #e5007e;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  stroke: #e5007e;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

/* Error */
.error-icon {
  animation: scale 0.3s forwards;
}
</style>
