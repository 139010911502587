<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'seo'" @change-tab="handleTabChange"></tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef" @saving="(val) => buttonLoading = val"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import seoTab from './components/seo/index.vue'

// import CustomButton from '@/components/buttons/customButton.vue'
import TabNavigation from '@/components/navigation/tabNavigation.vue'

const router = useRouter()
const route = useRoute()

const buttonLoading = ref(false)
const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'seo')

const tabs = [
  { name: 'seo', label: 'SEO', component: markRaw(seoTab) },
]

const currentTabComponent = computed(() => {
  const tab = tabs.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}
</script>