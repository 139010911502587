<template>
  <div class="w-full h-screen bg-white flex items-center justify-center">
    <div class="flex flex-col justify-center items-center space-y-10 w-full">
      <img :src="require('@/assets/logo/logoA.svg')" alt="logo" class="h-20"/>
      <form @submit.prevent="signIn" class="space-y-4 w-3/4 lg:w-2/4">
        <div class="flex flex-col w-full space-y-4">
          <customInput icon="mail" :show-icon="true" type="email" v-model="email" :placeholder="t('auth.emailPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>
          <customInput icon="passkey" :show-icon="true" type="password" v-model="password" :placeholder="t('auth.passwordPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>
        </div>

        <div class="flex justify-between items-center font-semibold">
          <div class="relative flex items-center">
            <input type="checkbox" id="rememberMe" :checked="rememberMe" @change="handleRememberMeChange" class="hidden"/>
            <div class="w-4 h-4 bg-primary/25 rounded flex justify-center items-center" :class="{ 'bg-primary': rememberMe }">
              <span v-if="rememberMe" class="material-symbols-outlined text-black font-semibold text-[18px]">done</span>
            </div>
            <label for="rememberMe" class="text-xs text-gray-600 ml-2"> {{ t('auth.rememberMe') }} </label>
          </div>
          <a href="#" class="text-xs text-primary hover:underline">{{ t('auth.forgotPassword') }}</a>
        </div>

        <customButton type="submit" :buttonText="t('auth.signInButton')" :isLoading="isLoading" :loadingText="t('auth.signingIn')" :fullWidth="true" color="secondary" size="md"/>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import customButton from '@/components/buttons/customButton.vue'
import customInput from '@/components/inputs/customInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const email = ref('')
const password = ref('')
const isLoading = ref(false)

const userEmail = computed(() => store.state.userEmail)
const rememberMe = computed(() => store.state.rememberMe)

const setUserData = store.commit.bind(store, 'setUserData')
const setRememberMe = store.dispatch.bind(store, 'setRememberMe')
const setUserEmail = store.dispatch.bind(store, 'setUserEmail')

const signIn = () => {
  if (!email.value || !password.value) {
    responseHandlerService.handleError(t('auth.fillAllFields'))
    return
  }

  if (rememberMe.value) {
    setUserEmail(email.value)
  } else {
    setUserEmail('')
  }

  isLoading.value = true
  axios.post(auth.signIn, {
        email: email.value,
        password: password.value,
      })
      .then((res) => {
        isLoading.value = false
        setUserData(res.data.data)
        router.push('/assets')
      })
      .catch((err) => {
        isLoading.value = false
        console.log(err.response)
        const errorCode = err.response.data.code
        let errorMessage = t('auth.error.general')
        if (errorCode === 'invalid_credentials') {
          errorMessage = t('auth.error.invalidCredentials')
        } else if (errorCode === 'account_locked') {
          errorMessage = t('auth.error.accountLocked')
        }
        responseHandlerService.handleError(errorMessage)
      })
}

const handleRememberMeChange = (event) => {
  setRememberMe(event.target.checked)
  if (event.target.checked) {
    setUserEmail(email.value)
  } else {
    setUserEmail('')
  }
}

onMounted(() => {
  if (rememberMe.value) {
    email.value = userEmail.value
  }
})
</script>