<template>
  <tab-navigation :tabs="tabs" :current-tab="currentTab" @change-tab="changeTab">
    <template #right-content></template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" :socialAuths="socialAuths" :loading="loading"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from '@/plugins/axiosInstance'
import { useRoute, useRouter } from 'vue-router'
import postList from './components/posts/index.vue'
import newContent from './components/posts/components/postCreator/index.vue'
import { socialMedia } from '@/networking/urlManager'
import { useStore } from 'vuex'
import TabNavigation from '@/components/navigation/tabNavigation.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()

const socialAuths = ref({})
const loading = ref(false)

const tabs = [
  { name: 'content', label: 'İçerikler', component: postList },
  { name: 'new-content', label: 'Yeni İçerik', component: newContent },
]

const currentTab = ref(route.query.tab || 'content')

const currentTabComponent = computed(() => {
  const tab = tabs.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const changeTab = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

const checkAuth = async () => {
  try {
    loading.value = true
    const response = await axios.get(socialMedia.checkSocialAuth, {
      params: {
        assetId: store.state.assetId,
      },
    })
    socialAuths.value = response.data.data
    loading.value = false
  } catch (error) {
    console.error('Error checking authentication status:', error)
  }
}

const checkPendingIntegration = () => {
  let { code, integration, tab } = route.query
  if (code && integration && tab) {
    currentTab.value = tab
  }
}

const updateCurrentTab = () => {
  currentTab.value = route.query.tab || 'content'
}

onMounted(async () => {
  await checkAuth()
  checkPendingIntegration()
  updateCurrentTab()
})

watch(() => route.query.tab, (newTab) => {
  currentTab.value = newTab || 'content'
})

</script>