export const responseHandlerService = {
  errorHandler: null,
  successHandler: null,
  warningHandler: null,
  unauthorizedSnackbarDisplayed: false,

  setErrorHandler(handler) {
    this.errorHandler = handler
  },

  setSuccessHandler(handler) {
    this.successHandler = handler
  },

  setWarningHandler(handler) {
    this.warningHandler = handler
  },

  handleError(error) {
    if (this.errorHandler) {
      if (typeof error === 'string') {
        this.errorHandler({ message: error })
      } else {
        this.errorHandler(error)
      }
    } else {
      console.error('No error handler provided!')
    }
  },

  handleSuccess(message) {
    if (this.successHandler) {
      if (typeof message === 'string') {
        this.successHandler(message)
      } else {
        this.successHandler(message)
      }
    } else {
      console.log('No success handler provided!')
    }
  },

  handleWarning(message) {
    if (this.warningHandler) {
      if (typeof message === 'string') {
        this.warningHandler(message)
      } else {
        this.warningHandler(message)
      }
    } else {
      console.warn('No warning handler provided!')
    }
  },
}