<template>
  <div class="component-layout flex h-[45vh]">
    <!-- Left side: KeywordTable -->
    <div class="w-full pr-4 flex flex-col h-full overflow-hidden">
      <KeywordTable :keywords="keywords" :is-loading="loading" @update:keywords="updateKeywords"/>
    </div>

    <!-- Right side: other components -->
    <div class="w-full pl-4 flex flex-col space-y-4 overflow-auto h-full">
      <!-- Document Upload Section -->
      <div class="bg-white rounded-md p-4 w-full">
        <div class="flex items-center justify-between">
          <div class="flex-grow">
            <h3 class="text-sm font-semibold mb-1">{{ t('settings.components.configuration.documentUploadLabel') }}</h3>
            <p class="text-xs mb-2 text-gray-500">
              {{ t('settings.components.configuration.documentUploadDescription') }} </p>
          </div>
          <CustomButton :button-text="t('general.view')" @click="showDocumentModal = true" size="sm"/>
        </div>
      </div>

      <!-- Memory History Section -->
      <div class="bg-white rounded-md p-4 w-full">
        <div class="flex items-center justify-between">
          <div class="flex-grow">
            <h3 class="text-sm font-semibold mb-1">{{ t('settings.components.configuration.memoryHistoryLabel') }}</h3>
            <p class="text-xs mb-2 text-gray-500">
              {{ t('settings.components.configuration.memoryHistoryDescription') }} </p>
          </div>
          <CustomButton :button-text="t('general.view')" @click="openMemoryHistoryModal" size="sm"/>
        </div>
      </div>

      <!-- Engine Location Selector Section -->
      <div class="bg-white rounded-md p-4 w-full">
        <div class="flex items-center justify-between">
          <div class="flex-grow">
            <h3 class="text-sm font-semibold mb-1">{{ t('settings.components.configuration.engineLocationLabel') }}</h3>
            <p class="text-xs mb-2 text-gray-500">
              {{ t('settings.components.configuration.engineLocationDescription') }} </p>
          </div>
          <CustomButton :button-text="t('general.view')" @click="showEngineLocationModal = true" size="sm"/>
        </div>
      </div>

      <!-- Auto Optimize Card -->
      <div class="bg-white rounded-md p-4 w-full">
        <div class="flex items-center justify-between">
          <div class="flex-grow">
            <h3 class="text-sm font-semibold mb-1">{{ t('settings.components.configuration.autoOptimizeTitle') }}</h3>
            <p class="text-xs mb-2 text-gray-500">{{ t('settings.components.configuration.autoOptimizeDescription') }}</p>
          </div>
          <CustomButton :button-text="t('settings.components.configuration.optimizeButton')" @click="showConfirmDialog = !showConfirmDialog" :is-loading="isOptimizing || loading" :loading-text="loading ? t('general.loading') : t('settings.components.configuration.optimizing')" :is-disabled="isAutoOptimized || loading" :disable-gray="true" size="sm" :show-tooltip="isAutoOptimized" :tooltip-text="isAutoOptimized ? t('settings.components.configuration.autoOptimizedTooltip') : ''" tooltip-color="secondary"/>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
  <documentModal v-model="showDocumentModal" @update:show="showDocumentModal = $event"/>
  <memoryHistory ref="memoryHistoryRef"/>
  <engineLocationModal v-model="showEngineLocationModal" v-model:locations="searchEngineLocations"/>
  <customDialog v-model="showConfirmDialog" :title="t('settings.components.configuration.confirmDialogTitle')" :message="t('settings.components.configuration.confirmDialogMessage')" :showCancelButton="true" :cancel-button-text="t('settings.components.configuration.confirmDialogCancel')" :confirm-button-text="t('settings.components.configuration.confirmDialogConfirm')" @confirm="confirmAutoOptimizeProcess" @update:show="showConfirmDialog = $event"/>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import axios from '@/plugins/axiosInstance'

// Global Components
import KeywordTable from './components/keywordTable/index.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import memoryHistory from '@/views/settings/components/configuration/components/memoryHistory/index.vue'
import documentModal from './components/assetsInformation/documentModal.vue'
import customDialog from '@/components/modals/customDialog.vue'
import engineLocationModal from '../../../../components/engineLocationSelector/modal/index.vue'

// Services
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const { t } = useI18n()
const store = useStore()

const props = defineProps(['keywordList'])
const emit = defineEmits(['keywords', 'saving'])

const memoryHistoryRef = ref(null)

const searchEngineLocations = ref({ google: [], bing: [], yandex: [] })
const keywords = ref([])
const showDocumentModal = ref(false)
const showEngineLocationModal = ref(false)
const loading = ref(false)

// Auto Optimize ile ilgili reactive değişkenler
const showConfirmDialog = ref(false)
const isOptimizing = ref(false)
const isAutoOptimized = ref(false)

const openMemoryHistoryModal = () => {
  if (memoryHistoryRef.value && typeof memoryHistoryRef.value.toggleSidebar === 'function') {
    memoryHistoryRef.value.toggleSidebar()
  }
}

const fetch = async () => {
  loading.value = true
  axios.get(settings.assetConfiguration, { params: { assetId: store.state.assetId } })
      .then(response => {
        keywords.value = response.data.data.keywords
        isAutoOptimized.value = response.data.data.isAutoOptimized
        searchEngineLocations.value = response.data.data.locations.length ? response.data.data.locations.reduce(
            (acc, location) => {
              const engineName = getEngineName(location.engineId)
              acc[engineName].push({
                id: location.id,
                locationId: location.locationId,
                name: location.locationName,
                engineId: location.engineId,
                canonicalName: location.canonicalName,
                state: location.state,
              })
              return acc
            },
            { google: [], bing: [], yandex: [] },
        ) : searchEngineLocations.value
      })
      .catch(error => {
        responseHandlerService.handleError('Hata')
        console.error(error)
      }).finally(() => {
    setTimeout(() => loading.value = false, 500)
  })
}

const updateKeywords = (newKeywords) => {
  keywords.value = newKeywords
}

// Auto Optimize işlem fonksiyonu
const confirmAutoOptimizeProcess = async () => {
  isOptimizing.value = true
  try {
    await axios.post(settings.autoOptimize, { assetId: store.state.assetId })
    responseHandlerService.handleSuccess(t('settings.components.configuration.optimizeSuccess'))
  } catch (err) {
    console.log(err)
    responseHandlerService.handleError(t('settings.components.configuration.optimizeError'))
  } finally {
    isOptimizing.value = false
  }
}

const save = async () => {
  emit('saving', true)
  axios.put(settings.assetConfiguration, {
    keywords: keywords.value,
    locations: searchEngineLocations.value,
    assetId: store.state.assetId,
  })
      .then(() => {
        responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
      })
      .catch(error => {
        responseHandlerService.handleError(t('settings.errorUpdatingSettings'))
      }).finally(
      () => emit('saving', false),
  )
}

const getEngineName = (engineNumber) => {
  switch (engineNumber) {
    case 1:
      return 'yandex'
    case 2:
      return 'google'
    case 3:
      return 'bing'
    default:
      return `Engine ${engineNumber}`
  }
}

defineExpose({
  save,
})

onMounted(() => {
  fetch()
})
</script>