<template>
  <div class="max-w-full mx-auto bg-white p-4 rounded-lg shadow-sm relative">
    <header class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-3 gap-2">
      <div>
        <h1 class="text-lg font-semibold text-gray-800 truncate">{{ entityData.title }}</h1>
        <p class="text-sm text-gray-600">
          {{ entityData.category || t('entity.components.contentReview.noCategoryProvided') }} </p>
      </div>
      <div class="flex flex-wrap gap-2">
        <CustomButton @click="openHistoriesSidebar" icon="history" :button-text="t('entity.components.contentReview.historyButton')" color="secondary" size="sm"/>
        <CustomButton @click="openRecommendationsModal" :is-disabled="!hasRecommendations" icon="recommend" :button-text="t('entity.components.contentReview.recommendationsButton')" color="primary" size="sm"/>
        <CustomButton v-if="entityUrl" @click="navigateToContent" icon="open_in_new" :button-text="t('entity.components.contentReview.navigateToContentButton')" color="primary" size="sm"/>
        <CustomButton v-if="canOptimize" @click="fetchAnalyzedContent" :is-loading="isAnalyzing" :is-disabled="isAnalyzing" :loading-text="t('entity.components.contentReview.optimizingText')" :button-text="optimizeButtonText" color="accent" size="sm"/>
      </div>
    </header>

    <main class="grid grid-cols-1 xl:grid-cols-3 gap-4">
      <!-- Sol Sütun: Puan ve Anahtar Kelimeler -->
      <div class="col-span-1 border border-gray-200 p-3 rounded-md" style="height: calc(67vh - 100px); overflow-y: auto;">
        <h2 class="text-sm font-semibold mb-2">{{ t('entity.components.contentReview.scoreLabel') }}</h2>
        <ScoreChart v-if="entityData.score" :score="entityData.score" class="mb-2"/>
        <p v-if="entityData.scoreMessage" class="text-xs mb-4">{{ entityData.scoreMessage }}</p>
        <h2 class="text-sm font-semibold mb-2">{{ t('entity.components.contentReview.keywordsLabel') }}</h2>
        <div v-if="entityData.keywords && entityData.keywords.length" class="flex flex-wrap max-h-36 overflow-y-auto thin-scrollbar">
          <span v-for="keyword in entityData.keywords" :key="keyword.id" class="px-2 py-1 bg-gray-100 rounded-full text-xs m-1 whitespace-nowrap">
            {{ keyword.name }}
          </span>
        </div>
      </div>

      <!-- Orta Sütun: İçerik -->
      <div class="col-span-2 border border-gray-200 p-3 rounded-md">
        <h2 class="text-sm font-semibold mb-2">{{ t('entity.components.contentReview.contentLabel') }}</h2>
        <div v-if="compressedContent" class="prose text-xs overflow-y-auto thin-scrollbar p-2" style="height: calc(67vh - 160px);" v-html="compressedContent"></div>
      </div>
    </main>

    <footer class="mt-3 flex justify-end">
      <CustomButton v-if="entityData.isActive === 2" @click="saveChanges" :is-loading="isLoading" :is-disabled="isLoading" :loading-text="t('entity.components.contentReview.updatingContentText')" :button-text="t('entity.components.contentReview.updateContentButton')" color="primary" size="sm"/>
    </footer>

    <CustomModal v-model="showRecommendationsModal" :modal-title="t('entity.components.contentReview.recommendationsTitle')">
      <div v-if="hasRecommendations" class="text-xs space-y-2">
        <p v-if="entityData.semanticMessage">
          <strong>{{ t('entity.components.contentReview.semanticStructure') }}:</strong> {{ entityData.semanticMessage }} </p>
        <p v-if="entityData.spellingMessage">
          <strong>{{ t('entity.components.contentReview.spelling') }}:</strong> {{ entityData.spellingMessage }} </p>
        <div v-if="entityData.seoShortcomings">
          <strong>{{ t('entity.components.contentReview.seoImprovements') }}:</strong>
          <ul class="list-disc list-inside mt-1">
            <li v-for="(shortcoming, index) in JSON.parse(entityData.seoShortcomings)" :key="index">
              {{ shortcoming }}
            </li>
          </ul>
        </div>
      </div>
      <p v-else class="text-xs">{{ t('entity.components.contentReview.noRecommendations') }}</p>
    </CustomModal>

    <ReviseContentModal v-model="showReviseModal" :selected-message="selectedMessage" :selected-type="selectedType" :selected-data="selectedData" :module-type-id="1" @confirm="handleConfirm" :entity-id="entityData.optimizeId || entityData.id"/>

    <ContentHistories ref="contentHistory" :entity-id="entityData.id" :top-id="entityData.topId" @withdrawHistory="fetchEntityDetail"/>

    <div v-if="isLoading || isAnalyzing" class="absolute inset-0 bg-white bg-opacity-75 z-50 flex items-center justify-center">
      <Loading/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import CustomButton from '@/components/buttons/customButton.vue'
import Loading from '@/components/loadings/loading.vue'
import ReviseContentModal from '@/views/entity/components/reviseContentModal.vue'
import ContentHistories from './components/contentHistory/contentHistories.vue'
import ImageComponent from '@/components/general/imageComponent.vue'
import ScoreChart from '@/components/charts/scoreChart.vue'
import CustomModal from '@/components/modals/customModal.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  selectedContent: {
    type: [Number],
    required: true,
  },
})

const emit = defineEmits(['content-updated'])

const { t } = useI18n()
const store = useStore()

const contentHistory = ref(null)
const isLoading = ref(true)
const isAnalyzing = ref(false)
const showReviseModal = ref(false)
const showRecommendationsModal = ref(false)
const isRevisedEntityReceived = ref(false)
const selectedType = ref('')
const selectedMessage = ref('')
const selectedData = ref('')
const entityUrl = ref('')
const optimizeId = ref('')

const entityData = ref({})

const circumference = 2 * Math.PI * 45
const dashOffset = computed(() => {
  return circumference - (entityData.value.score / 100) * circumference
})

const canOptimize = computed(() => {
  const optimizableTypes = [2, 3, 6]
  return optimizableTypes.includes(entityData.value.entityTypeId)
})

const optimizeButtonText = computed(() => {
  return isRevisedEntityReceived.value
      ? t('entity.components.contentReview.reOptimizeButton')
      : t('entity.components.contentReview.optimizeButton')
})

const recommendationMessages = computed(() => {
  const { recomendationNotes } = entityData.value
  return {
    scoreMessage: recomendationNotes?.scoreMessage,
    semanticMessage: recomendationNotes?.semanticMessage,
    metaDescription: recomendationNotes?.metaDescription,
    ...(
        Array.isArray(recomendationNotes?.seoShortcomings)
            ? recomendationNotes.seoShortcomings.reduce((acc, shortcoming, index) => {
              acc[`shortcoming_${index}`] = shortcoming
              return acc
            }, {})
            : {}
    ),
  }
})

const compressedContent = computed(() => {
  return entityData.value.content || ''
})

const hasRecommendations = computed(() => {
  return entityData.value.semanticMessage || entityData.value.spellingMessage || entityData.value.seoShortcomings
})

const fetchEntityDetail = async (entityId = null) => {
  isLoading.value = true
  if (entityId) optimizeId.value = entityId
  try {
    const response = await axios.get(entity.getDetail, {
      params: {
        entityId: entityId || props.selectedContent,
        assetId: store.state.assetId,
      },
    })
    updateEntityData(response.data.data)
  } catch (error) {
    responseHandlerService.handleError(t('entity.components.contentReview.errorFetch'))
    optimizeId.value = ''
  } finally {
    isLoading.value = false
  }
}

const updateEntityData = (data) => {
  entityUrl.value = data.entityUrl || entityUrl.value
  entityData.value = {
    ...data,
    keywords: data.keywords || [],
    recommendedKeywords: data.recommendedKeywords ? JSON.parse(data.recommendedKeywords) : [],
    imageUrls: Array.isArray(data.imageUrls) ? data.imageUrls.filter(r => r.url || r.image) : [],
    recomendationNotes: {
      scoreMessage: data.scoreMessage || '',
      semanticMessage: data.semanticMessage || '',
      metaDescription: data.metaDescription || '',
      seoShortcomings: data.seoShortcomings || [],
      spellingMessage: data.spellingMessage || '',
    },
  }
}

const fetchAnalyzedContent = async () => {
  isAnalyzing.value = true
  try {
    const response = await axios.put(entity.assetContentEdit, {
      entityId: entityData.value.id,
      assetId: store.state.assetId,
    })
    const data = response.data.data

    updateEntityData({
      ...entityData.value,
      optimizeId: data.optimizeId || '',
      title: data.result.title || '',
      score: data.result.score || '',
      content: data.result.content || '',
      entityTypeId: 3,
      keywords: data.result.keywords || [],
      contentDifference: data.result.contentDifference || [],
      subTitle: data.result.spot || '',
      isActive: data.result.isActive || 1,
      recomendationNotes: {
        ...entityData.value.recomendationNotes,
        scoreMessage: data.result.scoreMessage || '',
      },
    })
    isRevisedEntityReceived.value = true
  } catch (error) {
    responseHandlerService.handleError(t('entity.components.contentReview.errorOptimize'))
  } finally {
    isAnalyzing.value = false
  }
}

const saveChanges = async () => {
  isLoading.value = true
  try {
    await axios.post(entity.saveEditedContent, {
      assetId: store.state.assetId,
      entityId: entityData.value.id,
      optimizeId: optimizeId.value,
    })
    responseHandlerService.handleSuccess(t('entity.components.contentReview.saveSuccess'))
    emit('content-updated')
  } catch (error) {
    responseHandlerService.handleError(t('entity.components.contentReview.errorUpdate'))
  } finally {
    isLoading.value = false
  }
}

const handleConfirm = (response) => {
  if (response) {
    updateEntityData({
      ...entityData.value,
      title: response.data.title || entityData.value.title,
      category: response.data.category || entityData.value.category,
      content: response.data.content || entityData.value.content,
      keywords: response.data.keywords || entityData.value.keywords,
      imageUrls: Array.isArray(response.data.imageUrls)
          ? response.data.imageUrls
          : typeof response.data.imageUrls === 'string'
              ? JSON.parse(response.data.imageUrls)
              : entityData.value.imageUrls,
      optimizeId: response.optimizeId || '',
      entityTypeId: response.data.entityTypeId || entityData.value.entityTypeId,
      isActive: response.data.isActive || entityData.value.isActive,
      contentDifference: response.data.contentDifference || entityData.value.contentDifference,
      recomendationNotes: {
        ...entityData.value.recomendationNotes,
        metaDescription: response.data.metaDescription || entityData.value.recomendationNotes.metaDescription,
      },
    })
  }
}

const navigateToContent = () => {
  window.open(entityUrl.value, '_blank')
}

const openHistoriesSidebar = () => {
  contentHistory.value.toggleSidebar()
}

const openRecommendationsModal = () => {
  if (hasRecommendations.value) showRecommendationsModal.value = true
}

onMounted(() => {
  fetchEntityDetail()
})

watch(() => props.selectedContent, () => {
  fetchEntityDetail()
})
</script>