<template>
  <div class="component-layout">
    <div class="bg-white shadow-md rounded-md overflow-hidden">
      <div class="border-b border-gray-200 px-4 py-3 flex items-center justify-between">
        <h2 class="text-lg font-semibold text-gray-900">{{ getPageTitle }}</h2>
        <CustomButton v-if="inspectedContentId" buttonText="Geri Dön" color="secondary" size="sm" @click="closeInspector"/>
      </div>

      <div v-if="!inspectedContentId">
        <CustomTable :data="contents" :columns="tableColumns" v-bind="tableProps" @row-click="showInspector" @search="handleSearch" @sort="handleSort" @filter="handleFilter" @page-change="handlePageChange">
          <template #table-actions>
            <CustomButton icon="add" iconPosition="right" @click="createNewContent" buttonText="Yeni İçerik" size="sm"/>
          </template>

          <template #platform="{ item }">
            <div class="flex items-center justify-center">
              <img :src="getPlatformIcon(item.platformId)" :alt="item.platformId" class="w-5 h-5 rounded-full">
            </div>
          </template>

          <template #content="{ item }">
            <p class="text-sm text-gray-900 truncate">{{ truncateMessage(item.message, 100) }}</p>
          </template>

          <template #status="{ item }">
            <div class="flex items-center justify-center text-xs w-full" :class="statusClass(item.isPublished)">
              <span class="material-symbols-outlined !text-[12px] ">{{ item.isPublished === 1 ? 'published_with_changes' : 'pending' }}</span>
              <p class="text-center w-16">{{ item.isPublished === 1 ? t('socialMedia.components.posts.status.published') : t('socialMedia.components.posts.status.draft') }}</p>
            </div>
          </template>

          <!--          <template #engagement="{ item }">-->
          <!--            <div v-if="item.engagement" class="flex items-center space-x-2">-->
          <!--              <span class="material-symbols-outlined flex-shrink-0 text-gray-400">group</span>-->
          <!--              <p class="text-sm text-gray-500">{{ item.engagement.toLocaleString() }} etkileşim</p>-->
          <!--            </div>-->
          <!--          </template>-->

          <template #date="{ item }">
            <div class="flex justify-center text-center">
              <span class="material-symbols-outlined !text-[16px]">calendar_month</span>
              <p class="w-36 text-xs">
                <time :datetime="item.publishedDate">{{ formatDate(item.publishedDate) }}</time>
              </p>
            </div>
          </template>
        </CustomTable>
      </div>

      <div v-else-if="inspectedContentId" class="h-[600px]">
        <PostInspector :commentId="commentId" :postId="inspectedContentId" @update:isPublished="updateIsPublished" @close="closeInspector"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import axios from '@/plugins/axiosInstance'
import CustomTable from '@/components/table/customTable.vue'
import PostInspector from './components/postInspector/index.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import { socialMedia } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import InstagramIcon from '@/assets/icons/instagram.svg'
import FacebookIcon from '@/assets/icons/facebook.svg'
import TwitterIcon from '@/assets/icons/twitterx.svg'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const { t } = useI18n()

const contents = ref([])
const currentPage = ref(1)
const itemsPerPage = ref(15)
const totalContents = ref(0)
const pageCount = ref(0)
const loading = ref(false)
const sortBy = ref('')
const sortOrder = ref('DESC')
const searchQuery = ref('')
const filters = ref({})

const inspectedContentId = ref(null)
const commentId = ref(null)

const tableColumns = [
  { key: 'platform', label: t('socialMedia.components.posts.tableHeaders.platform'), sortable: false, width: 'w-1/12', align: 'center' },
  { key: 'content', label: t('socialMedia.components.posts.tableHeaders.content'), sortable: false, width: 'w-4/12', align: 'left' },
  { key: 'status', label: t('socialMedia.components.posts.tableHeaders.status'), sortable: false, width: 'w-2/12', align: 'center' },
  { key: 'date', label: t('socialMedia.components.posts.tableHeaders.date'), sortable: true, width: 'w-3/12', align: 'center' },
]

const filterOptions = [
  {
    id: 'status',
    icon: 'filter_alt',
    iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
    showText: false,
    multiSelect: false,
    items: [
      { id: 1, name: t('socialMedia.components.posts.filterItems.all') },
      { id: 2, name: t('socialMedia.components.posts.filterItems.published') },
      { id: 3, name: t('socialMedia.components.posts.filterItems.draft') },
    ],
  },
  {
    id: 'platform',
    icon: 'devices',
    iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
    showText: false,
    multiSelect: true,
    items: [
      { id: 1, name: 'Instagram' },
      { id: 2, name: 'Facebook' },
      { id: 3, name: 'Twitter' },
    ],
  },
]

const tableProps = computed(() => ({
  isLoading: loading.value,
  itemsPerPage: itemsPerPage.value,
  totalCount: totalContents.value,
  pageCount: pageCount.value,
  currentPage: currentPage.value,
  sortOrder: sortOrder.value,
  sortKey: sortBy.value,
  filterOptions,
  minHeight: '53vh',
  maxHeight: '53vh',
  showSearch: true,
  showFilter: true,
}))

const getPageTitle = computed(() => {
  return inspectedContentId.value
      ? t('socialMedia.components.posts.pageTitle.contentInspection')
      : t('socialMedia.components.posts.pageTitle.contentManagement')
})

const fetchContents = async () => {
  loading.value = true
  try {
    const response = await axios.get(socialMedia.getPosts, {
      params: {
        assetId: store.state.assetId,
        q: searchQuery.value,
        page: currentPage.value - 1,
        sort: sortBy.value,
        order: sortOrder.value,
        ...filters.value,
      },
    })
    contents.value = response.data.data.detail
    totalContents.value = response.data.data.totalCount
    pageCount.value = response.data.data.pageCount
  } catch (err) {
    console.error(err)
    responseHandlerService.handleError(t('socialMedia.components.posts.fetchError'))
  } finally {
    loading.value = false
  }
}

const handleSearch = (query) => {
  searchQuery.value = query
  currentPage.value = 1
  fetchContents()
}

const handleSort = ({ key, order }) => {
  sortBy.value = key
  sortOrder.value = order
  fetchContents()
}

const handleFilter = (newFilters) => {
  filters.value = newFilters
  currentPage.value = 1
  fetchContents()
}

const handlePageChange = (page) => {
  currentPage.value = page
  fetchContents()
}

const showInspector = (item) => {
  inspectedContentId.value = item.id
  commentId.value = item.postId
}

const createNewContent = () => {
  router.push({ query: { tab: 'new-content' } })
}

const closeInspector = () => {
  inspectedContentId.value = null
}

const formatDate = (dateString) => {
  if (!dateString) return t('common.noDate')

  const formattedDate = dayjs(dateString).locale('tr').format('D MMMM YYYY HH:mm')
  if (formattedDate === 'Invalid Date') return t('common.noDate')

  return formattedDate
}

const statusClass = (isPublished) => {
  return isPublished === 1 ? 'text-green-600' : 'text-yellow-600'
}

const getPlatformIcon = (platformId) => {
  switch (platformId) {
    case 1:
      return InstagramIcon
    case 2:
      return FacebookIcon
    case 3:
      return TwitterIcon
    default:
      return ''
  }
}

const truncateMessage = (message, maxLength) => {
  return message.length > maxLength ? message.slice(0, maxLength) + '...' : message
}

onMounted(() => {
  fetchContents()
})

watch([filters, sortBy, sortOrder], fetchContents)
</script>