<template>
  <div class="relative z-40">
    <button @click="toggleMenu" class="p-2 rounded-md hover:bg-gray-100 focus:outline-none flex items-center">
      <span :class="option.iconClass">{{ option.icon }}</span> <span v-if="option.showText" class="ml-1 text-sm">{{ option.text }}</span>
    </button>
    <transition name="fade">
      <div v-show="isOpen" class="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-10 text-sm">
        <div v-for="item in option.items" :key="item.id" @click="handleSelect(item.id)" class="px-3 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
          {{ item.name }} <span v-if="Array.isArray(selected) ? selected.includes(item.id) : selected === item.id" class="material-symbols-outlined text-secondary !text-sm">
            check
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, inject, onMounted } from 'vue'

const props = defineProps({
  option: Object,
  selected: [Array, String, Number],
})

const emit = defineEmits(['select'])

const openMenu = inject('openMenu')
const isOpen = computed(() => openMenu.value === `filter-${props.option.id}`)

const handleSelect = (itemId) => {
  emit('select', itemId)
}

const toggleMenu = () => {
  openMenu.value = isOpen.value ? null : `filter-${props.option.id}`
}

const selectDefaultItem = () => {
  const defaultItem = props.option.items.find(item => item.default)
  if (defaultItem) {
    emit('select', defaultItem.id)
  }
}

onMounted(selectDefaultItem)
</script>