<template>
  <div class="keyword-table h-full flex flex-col">
    <customTable :data="keywordsTableData" :columns="columns" :is-loading="isLoading" :show-search="false" max-height="40vh">
      <template #table-actions>
        <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.addKeywords')" @click="openAddKeywordModal" size="sm" icon="add" icon-position="right"/>
      </template>
      <template #extra-actions>
        <div class="flex items-center space-x-2">
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.downloadTemplate')" @click="downloadExcelTemplate" size="sm" icon="download" icon-position="right" color="secondary" :show-icon="true"/>
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.uploadExcel')" @click="triggerFileInput" size="sm" icon="upload" icon-position="right" :show-icon="true"/>
          <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" accept=".xlsx, .xls"/>
        </div>
      </template>
      <template #actions="{ item }">
        <div class="flex items-center justify-center">
          <button @click.stop="removeKeyword(item)" class="text-red-500 hover:text-red-700">
            <span class="material-symbols-outlined">delete</span>
          </button>
        </div>
      </template>
      <template #priority="{ item }">
        <p class="text-center">{{ item.priority || '-' }}</p>
      </template>
    </customTable>

    <customModal v-model="showAddKeywordModal" :modal-title="t('settings.components.configuration.components.keywordsTable.addNewKeywords')" width="w-[500px]">
      <div class="space-y-3">
        <div v-for="(keyword, index) in newKeywords" :key="index" class="flex items-center">
          <div class="flex-grow mr-2">
            <CustomInput v-model="keyword.value" :placeholder="t('settings.components.configuration.components.keywordsTable.enterKeyword')" size="sm"/>
          </div>
          <div class="w-28 mr-2">
            <CustomSelect v-model="keyword.priority" :options="priorityOptions" :placeholder="t('settings.components.configuration.components.keywordsTable.selectPriority')" size="sm"/>
          </div>
          <div class="w-8 flex justify-center">
            <button v-if="index !== 0" @click="removeKeywordField(index)" class="text-red-500 hover:text-red-700 p-1">
              <span class="material-symbols-outlined text-sm">delete</span>
            </button>
          </div>
        </div>
        <div v-if="newKeywords.length < 5" @click="addKeywordField" class="flex items-center text-sm text-secondary hover:text-secondary/70 cursor-pointer mt-2">
          <span class="material-symbols-outlined mr-1 text-sm">add</span> <span>{{ t('settings.components.configuration.components.keywordsTable.addAnotherKeyword') }}</span>
        </div>
        <div class="flex justify-end mt-4">
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.save')" @click="saveNewKeywords" size="sm" :disabled="!isAnyValidKeyword"/>
        </div>
      </div>
    </customModal>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import customTable from '@/components/table/customTable.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import CustomSelect from '@/components/inputs/customSelect.vue'
import customModal from '@/components/modals/customModal.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import * as XLSX from 'xlsx'

const { t } = useI18n()

const props = defineProps({
  keywords: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:keywords'])

const showAddKeywordModal = ref(false)
const newKeywords = ref([{ value: '', priority: null }])
const fileInput = ref(null)

const columns = computed(() => [
  { key: 'name', label: t('settings.components.configuration.components.keywordsTable.keyword'), width: '1/2' },
  { key: 'priority', label: t('settings.components.configuration.components.keywordsTable.priority'), width: '1/4', align: 'center' },
  { key: 'actions', label: t('settings.components.configuration.components.keywordsTable.actions'), width: '1/4', align: 'center' },
])

const keywordsTableData = computed(() =>
    props.keywords
        .filter(keyword => keyword.state !== 3)
        .map((keyword, index) => ({
          id: index,
          name: keyword.name,
          state: keyword.state,
          priority: keyword.priority,
        }))
        .sort((a, b) => b.state - a.state),
)

const priorityOptions = computed(() =>
    Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() })),
)

const isAnyValidKeyword = computed(() =>
    newKeywords.value.some(keyword => keyword.value.trim() !== '' && keyword.priority !== null),
)

const openAddKeywordModal = () => {
  newKeywords.value = [{ value: '', priority: null }]
  showAddKeywordModal.value = true
}

const addKeywordField = () => {
  if (newKeywords.value.length < 5) {
    newKeywords.value.push({ value: '', priority: null })
  }
}

const removeKeywordField = (index) => {
  newKeywords.value.splice(index, 1)
}

const saveNewKeywords = () => {
  const validKeywords = newKeywords.value
      .filter(k => k.value.trim() !== '' && k.priority !== null)

  const updatedKeywords = [...props.keywords]
  let hasNewKeyword = false

  validKeywords.forEach(newKeyword => {
    const newKeywordLower = newKeyword.value.trim().toLowerCase()
    const existingKeywordIndex = updatedKeywords.findIndex(k => k.name.toLowerCase() === newKeywordLower)

    if (existingKeywordIndex !== -1) {
      if (updatedKeywords[existingKeywordIndex].state === 3) {
        updatedKeywords[existingKeywordIndex].state = 1
        updatedKeywords[existingKeywordIndex].priority = newKeyword.priority
        responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.keywordReactivated', { keyword: newKeyword.value }))
        hasNewKeyword = true
      } else {
        responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.keywordAlreadyExists', { keyword: newKeyword.value }))
      }
    } else {
      updatedKeywords.push({ name: newKeyword.value.trim(), state: 2, priority: newKeyword.priority })
      responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.newKeywordAdded', { keyword: newKeyword.value }))
      hasNewKeyword = true
    }
  })

  if (hasNewKeyword) {
    emit('update:keywords', updatedKeywords)
    showAddKeywordModal.value = false
  }
}

const removeKeyword = (keyword) => {
  const updatedKeywords = props.keywords.map(k => {
    if (k.name === keyword.name) {
      if (k.state === 1) {
        return { ...k, state: 3, priority: null }
      } else if (k.state === 2) {
        return null
      }
    }
    return k
  }).filter(k => k !== null)

  emit('update:keywords', updatedKeywords)

  if (keyword.state === 1) {
    responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.keywordDeactivated', { keyword: keyword.name }))
  } else if (keyword.state === 2) {
    responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.keywordRemoved', { keyword: keyword.name }))
  }
}

const downloadExcelTemplate = () => {
  const template = [
    ['Keyword', 'Priority'],
    ['Example Keyword', 1],
    ['Another Keyword', 2],
  ]
  const ws = XLSX.utils.aoa_to_sheet(template)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Keywords')
  XLSX.writeFile(wb, 'keyword_template.xlsx')
}

const triggerFileInput = () => {
  fileInput.value.click()
}

const handleFileUpload = (event) => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const json = XLSX.utils.sheet_to_json(worksheet)

      const updatedKeywords = [...props.keywords]
      let newKeywordsCount = 0
      let reactivatedKeywordsCount = 0

      json.forEach(row => {
        const newKeywordLower = row.Keyword.trim().toLowerCase()
        const existingKeywordIndex = updatedKeywords.findIndex(k => k.name.toLowerCase() === newKeywordLower)

        if (existingKeywordIndex !== -1) {
          if (updatedKeywords[existingKeywordIndex].state === 3) {
            updatedKeywords[existingKeywordIndex].state = 1
            updatedKeywords[existingKeywordIndex].priority = row.Priority
            reactivatedKeywordsCount++
          }
          // Eğer kelime zaten aktifse (state 1 veya 2), hiçbir şey yapmıyoruz
        } else {
          updatedKeywords.push({ name: row.Keyword.trim(), state: 2, priority: row.Priority })
          newKeywordsCount++
        }
      })

      emit('update:keywords', updatedKeywords)

      if (newKeywordsCount > 0) {
        responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.excelUploadNewKeywords', { count: newKeywordsCount }))
      }
      if (reactivatedKeywordsCount > 0) {
        responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.excelUploadReactivatedKeywords', { count: reactivatedKeywordsCount }))
      }
      if (newKeywordsCount === 0 && reactivatedKeywordsCount === 0) {
        responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.excelUploadNoNewKeywords'))
      }
    }
    reader.readAsArrayBuffer(file)
  }
}

</script>