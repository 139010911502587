<template>
  <div class="component-layout">
    <div class="space-y-4">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6" :class="{ 'h-[65vh]': true }">
        <!-- Sol Sütun: Tanım (Dinamik Yükseklik) -->
        <div class="h-full">
          <div class="bg-white rounded-lg shadow-md p-4 transition-all duration-300 h-full">
            <h3 class="text-lg font-semibold mb-4 text-gray-700">{{ t('settings.components.informationList.aboutusLabel') }}</h3>
            <customInput type="textarea" :label="t('settings.components.informationList.aboutusLabel')" :description="t('settings.components.informationList.aboutusDescription')" containerClass="h-full flex flex-col" v-model="generalSettingList.aboutus" :loading="loading" :auto-grow="false" text-area-height="h-[50vh] resize-none"/>
          </div>
        </div>

        <!-- Sağ Sütun: Sosyal Medya ve İletişim -->
        <div class="h-full flex flex-col space-y-6">
          <div class="space-y-6 flex-1 flex flex-col">
            <!-- Sosyal Medya -->
            <div class="bg-white rounded-lg shadow-md p-4 transition-all duration-300 flex-1">
              <h3 class="text-lg font-semibold mb-4 text-gray-700">
                {{ t('settings.components.informationList.socialMedia') }}</h3>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <customInput icon="link" :show-icon="true" :label="t('settings.components.informationList.facebookLabel')" :description="t('settings.components.informationList.facebookDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.facebookUrl" :loading="loading"/>
                <customInput icon="link" :show-icon="true" :label="t('settings.components.informationList.instagramLabel')" :description="t('settings.components.informationList.instagramDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.instagramUrl" :loading="loading"/>
                <customInput icon="link" :show-icon="true" :label="t('settings.components.informationList.twitterLabel')" :description="t('settings.components.informationList.twitterDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.twitterUrl" :loading="loading"/>
                <customInput icon="link" :show-icon="true" :label="t('settings.components.informationList.linkedinLabel')" :description="t('settings.components.informationList.linkedinDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.linkedinUrl" :loading="loading"/>
              </div>
            </div>

            <!-- İletişim -->
            <div class="bg-white rounded-lg shadow-md p-4 transition-all duration-300 flex-1">
              <h3 class="text-lg font-semibold mb-4 text-gray-700">
                {{ t('settings.components.informationList.contact') }}</h3>
              <div class="grid grid-cols-1 gap-4">
                <customInput :label="t('settings.components.informationList.phoneLabel')" type="text" :description="t('settings.components.informationList.phoneDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.phone" :loading="loading"/>
                <customInput :label="t('settings.components.informationList.whatsappLabel')" type="phoneNumber" :description="t('settings.components.informationList.whatsappDescription')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="generalSettingList.wphone" :loading="loading"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- AI Asistanı Butonu için yeni bölüm -->
      <div class="bg-white rounded-md p-4 w-full">
        <div class="flex items-center justify-between">
          <div class="flex-grow">
            <h3 class="text-sm font-semibold mb-1">{{ t('settings.components.informationList.autoOptimizeTitle') }}</h3>
            <p class="text-xs mb-2 text-gray-500">{{ t('settings.components.informationList.autoOptimizeDescription') }}</p>
          </div>
          <CustomButton :button-text="t('general.view')" @click="openAILinkModal" size="sm"/>
        </div>
      </div>

      <!-- HTML Kodu Modalı -->
      <customModal v-model="showModal" modal-title="AI Asistanı Seçenekleri">
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <CustomButton button-text="Asistana Git" @click="goToAssistant" size="sm" icon="open_in_new" :showIcon="true" :fullWidth="true"/>
            <CustomButton button-text="HTML Kodunu Kopyala" @click="copyHTMLCode" size="sm" icon="content_copy" :showIcon="true" :fullWidth="true"/>
          </div>
        </div>
      </customModal>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import customInput from '@/components/inputs/customInput.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import customModal from '@/components/modals/customModal.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { settings } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'

const store = useStore()
const { t } = useI18n()
const emit = defineEmits(['saving'])

const showModal = ref(false)
const htmlCode = ref('')
const loading = ref(false)
const aiLink = ref('')

const generalSettingList = ref({
  aboutus: '',
  facebookUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  linkedinUrl: '',
  phone: '',
  wphone: '',
})

const assetInputLoading = computed(() => store.state.assetData.inputLoading)

const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.generalSettings, { params: { assetId: store.state.assetId } })
    generalSettingList.value = response.data.data
  } catch (error) {
    responseHandlerService.handleError(t('settings.errorFetchingSettings'))
    console.error(error)
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

const save = async () => {
  emit('saving', true)
  try {
    await axios.put(settings.generalSettings, { ...generalSettingList.value, assetId: store.state.assetId })
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
  } catch (error) {
    responseHandlerService.handleError(t('settings.errorUpdatingSettings'))
  } finally {
    emit('saving', false)
  }
}

const openAILinkModal = () => {
  aiLink.value = `${settings.aiIframe}?access_code=${store.state.assetId}`
  htmlCode.value = `<button onclick="window.open('${aiLink.value}', '_blank')">AI Asistanı</button>`
  showModal.value = true
}

const goToAssistant = () => {
  window.open(aiLink.value, '_blank')
}

const copyHTMLCode = () => {
  navigator.clipboard.writeText(htmlCode.value)
      .then(() => {
        responseHandlerService.handleSuccess('HTML kodu panoya kopyalandı!')
        showModal.value = false
      })
      .catch(() => responseHandlerService.handleError('Kopyalama işlemi başarısız oldu. Lütfen kodu manuel olarak kopyalayın.'))
}

onMounted(() => {
  fetch()
})

defineExpose({
  save,
})
</script>