<template>
  <div class="bg-white p-4 rounded-md w-full">
    <h2 class="text-xl font-semibold text-gray-800 mb-4">
      {{ t('settings.components.sources.websiteConfiguration') }} </h2>
    <div class="space-y-4">
      <custom-table :data="sourcesDataWithIds" :columns="tableColumns" :is-loading="loadingCategories" :show-search="false" :show-sort="false" :show-filter="false">
        <template #table-actions>
          <custom-button icon="add" iconPosition="right" @click="addSource" :button-text="t('settings.components.sources.components.sourceTable.addSourceButton')" size="sm"/>
        </template>
        <template #category="{ item }">
          <CustomDropdown v-model="item.sourceCategoryId" :options="categoryOptions" :loading="loadingCategories" size="sm" :show-search="true"/>
        </template>
        <template #getUrl="{ item }" v-if="Number(assetServiceType) !== 2">
          <custom-input icon="link" :show-icon="true" v-model="item.getUrl" type="url" size="sm"/>
        </template>
        <template #addUrl="{ item }">
          <custom-input icon="link" :show-icon="true" v-model="item.addUrl" type="url" size="sm"/>
        </template>
        <template #editUrl="{ item }" v-if="Number(assetServiceType) !== 2">
          <custom-input icon="link" :show-icon="true" v-model="item.editUrl" type="url" size="sm"/>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center justify-center">
            <button v-if="item.id !== 1" @click.stop="removeSource(item.id)" class="text-lobster-500 hover:text-lobster-600">
              <span class="material-symbols-outlined text-sm">delete</span>
            </button>
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import CustomDropdown from '@/components/inputs/customDropdown.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import CustomTable from '@/components/table/customTable.vue'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CustomButton from '@/components/buttons/customButton.vue'

const props = defineProps({
  useStore: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  assetServiceTypeProp: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['update:modelValue'])

const store = useStore()
const { t } = useI18n()

const categoryOptions = ref([])
const loadingCategories = ref(true)
let nextId = ref(1)

const sourcesData = ref(props.useStore ? store.state.assetData.sources : props.modelValue)

const sourcesDataWithIds = computed(() => {
  return sourcesData.value.map((item, index) => ({
    ...item,
    id: item.id || index + 1,
  }))
})

const assetServiceType = computed(() => {
  if (props.useStore) {
    return store.state.assetServiceType
  } else {
    return props.assetServiceTypeProp
  }
})

const tableColumns = computed(() => [
  { key: 'category', label: t('settings.components.sources.components.sourceTable.categoryHeader') },
  ...(Number(assetServiceType.value) !== 2 ? [{ key: 'getUrl', label: t('settings.components.sources.components.sourceTable.getUrlHeader') }] : []),
  { key: 'addUrl', label: t('settings.components.sources.components.sourceTable.addUrlHeader') },
  ...(Number(assetServiceType.value) !== 2 ? [{ key: 'editUrl', label: t('settings.components.sources.components.sourceTable.editUrlHeader') }] : []),
  { key: 'actions', label: '#', width: 'w-1/12', align: 'center' },
])

const addSource = () => {
  nextId.value++
  const newSource = { id: nextId.value, sourceCategoryId: '', getUrl: '', addUrl: '', editUrl: '' }
  sourcesData.value = [...sourcesData.value, newSource]
  updateSources(sourcesData.value)
}

const removeSource = (id) => {
  sourcesData.value = sourcesData.value.filter(item => item.id !== id)
  updateSources(sourcesData.value)
}

const updateSources = (newSources) => {
  if (props.useStore) {
    store.dispatch('setSources', newSources)
  } else {
    emit('update:modelValue', newSources)
  }
}

const fetchCategories = async () => {
  try {
    const response = await axios.get(settings.categories)
    categoryOptions.value = response.data.data.map(category => ({
      value: category.id,
      label: category.name,
    }))
  } catch (error) {
    responseHandlerService.handleError(t('settings.errorFetchingCategories'))
  } finally {
    loadingCategories.value = false
  }
}

onMounted(() => {
  fetchCategories()
  nextId.value = Math.max(...sourcesData.value.map(item => item.id || 0), 0)
})

watch(() => props.modelValue, (newValue) => {
  if (!props.useStore) {
    sourcesData.value = newValue
  }
}, { deep: true })

watch(() => store.state.assetData.sources, (newValue) => {
  if (props.useStore) {
    sourcesData.value = newValue
  }
}, { deep: true })
</script>