<template>
  <div class="component-layout relative min-h-[80vh]">
    <!-- Yükleme durumu için overlay -->
    <div v-if="loading" class="loading-overlay">
      <Loading/>
    </div>
    <div v-else class="space-y-6">
      <div class="bg-white p-4 rounded-md w-full">
        <div class="flex flex-col w-full">
          <div class="mb-4">
            <h3 class="text-lg font-bold text-gray-700">
              {{ t('settings.components.schedule.title') }} </h3>
            <p class="text-gray-500 text-xs mt-1">
              {{ t('settings.components.schedule.description') }} </p>
          </div>

          <div class="overflow-x-auto">
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ t('settings.components.schedule.day') }}
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ t('settings.components.schedule.times') }}
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="day in dayOptions" :key="day.day" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <!-- Gün seçimi input -->
                    <input type="checkbox" :id="'day-' + day.day" :checked="isSelectedDay(day)" @change="toggleDay(day)" class="h-3 w-3 text-primary focus:ring-primary border-gray-300 rounded"/>
                    <label :for="'day-' + day.day" class="ml-2 block text-xs font-medium text-gray-900">
                      {{ t(`general.dayNames.${day.day}`) }} </label>
                  </div>
                </td>
                <td class="px-6 py-4">
                  <!-- Seçili gün olup olmadığını kontrol et -->
                  <div v-if="isSelectedDay(day)" class="overflow-x-auto">
                    <div class="flex md:grid md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-2 min-w-max md:min-w-0">
                      <!-- Zaman seçimi butonları -->
                      <button v-for="time in timeOptions" :key="time.time" @click="toggleTime(time, day.day)" class="flex-shrink-0 px-2 py-1 rounded-full text-xs transition-colors duration-200 focus:outline-none" :class="isSelectedTime(time, day.day) ? 'bg-primary text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'">
                        {{ time.time }}
                      </button>
                    </div>
                  </div>
                  <p v-else class="text-xs text-gray-500 italic py-1">
                    {{ t('settings.components.schedule.selectDayFirst') }} </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-2">
            <!-- Zaman seçimi limiti -->
            <p class="text-xs text-lobster-500">{{ t('settings.components.schedule.timeSelectionLimit') }}</p>
          </div>
        </div>
      </div>

      <!-- Otomatik Yayınlama Kartı -->
      <div class="bg-white shadow rounded-md p-4">
        <h3 class="font-semibold text-gray-800 mb-2">
          {{ t('settings.components.schedule.automaticPublishingTitle') }} </h3>
        <div class="flex items-center justify-between">
          <div class="space-y-2">
            <p class="text-sm text-gray-500">{{ t('settings.components.schedule.automaticPublishingDescription') }}</p>
            <p class="text-xs text-lobster-500">{{ t('settings.components.schedule.automaticPublishingNote') }}</p>
          </div>
          <div class="flex items-center space-x-2">
            <span class="text-xs font-medium text-gray-700">
              {{ automaticPublishing === 1 ? t('general.on') : t('general.off') }}
            </span>
            <!-- Otomatik Yayınlama Butonu -->
            <button @click="toggleAutomaticPublishing" class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" :class="automaticPublishing === 1 ? 'bg-primary' : 'bg-gray-200'">
              <span class="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out" :class="automaticPublishing === 1 ? 'translate-x-5' : 'translate-x-0'"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { useStore } from 'vuex'
import Loading from '@/components/loadings/loading.vue'

const emit = defineEmits(['update:modelValue', 'update:automaticPublishing', 'saving'])

const store = useStore()

const selectedDates = ref({ times: [], days: [] })
const automaticPublishing = ref(2)
const loading = ref(true)

const { t } = useI18n()

const dayOptions = [
  { day: 1, name: 'monday' },
  { day: 2, name: 'tuesday' },
  { day: 3, name: 'wednesday' },
  { day: 4, name: 'thursday' },
  { day: 5, name: 'friday' },
  { day: 6, name: 'saturday' },
  { day: 7, name: 'sunday' },
]

const timeOptions = Array.from({ length: 12 }, (_, i) => ({
  time: dayjs().hour(i + 8).format('HH:00'),
}))

const selected = ref({
  days: [],
  times: [],
})

const isSelectedDay = computed(() => (day) => {
  return selected.value.days.some((d) => d.day === day.day && d.state !== 3)
})

const isSelectedTime = computed(() => (time, dayId) => {
  const selectedTimesForDay = selected.value.times.filter((t) => t.dayId === dayId)
  return selectedTimesForDay.some((t) => t.time === time.time && t.state !== 3)
})

// Gün seçimini aç/kapat
const toggleDay = (day) => {
  const dayIndex = selected.value.days.findIndex((d) => d.day === day.day)
  if (dayIndex > -1) {
    const item = selected.value.days[dayIndex]
    if (item.state === 2) selected.value.days.splice(dayIndex, 1)
    else if (item.state === 3) selected.value.days[dayIndex].state = 1
    else selected.value.days[dayIndex].state = 3
    selected.value.times = selected.value.times.map((item) => ({
      ...item,
      state: selected.value.days.find((day) => item.dayId === day.day)?.state || item.state,
    }))
  } else {
    selected.value.days.push({ ...day, state: 2, assetId: store.state.assetId })
  }
  selectedDates.value = selected.value
}

// Zaman seçimini aç/kapat
const toggleTime = (time, dayId) => {
  const selectedTimesForDay = selected.value.times.filter((t) => t.dayId === dayId)
  const timeIndex = selectedTimesForDay.findIndex((t) => t.time === time.time)

  if (timeIndex > -1) {
    const item = selectedTimesForDay[timeIndex]
    const itemIndex = selected.value.times.indexOf(item)
    if (item.state === 2) selected.value.times.splice(itemIndex, 1)
    else if (item.state === 3) selected.value.times[itemIndex].state = 1
    else selected.value.times[itemIndex].state = 3
  } else {
    const isLimitReached = selectedTimesForDay.filter((t) => t.state === 1 || t.state === 2).length >= 3
    if (!isLimitReached) {
      selected.value.times.push({
        dayId,
        time: time.time,
        state: 2,
        assetId: store.state.assetId,
        timezone: 'Europe/Istanbul',
      })
    } else {
      responseHandlerService.handleWarning(t('settings.components.schedule.limitWarning'))
    }
  }
  selectedDates.value = selected.value
}

// Otomatik yayını aç/kapat
const toggleAutomaticPublishing = () => {
  automaticPublishing.value = automaticPublishing.value === 1 ? 2 : 1
}

// Başlangıç verilerini hazırlama
const initializeData = () => {
  selected.value = {
    days: selectedDates.value.days.map(d => {
      const dayData = dayOptions.find(day => day.day === d.day)
      return { ...d, name: dayData.name, state: 1 }
    }),
    times: selectedDates.value.times.map(t => ({ ...t, state: 1 })),
  }
}

// Verileri izleme ve değişiklikleri uygulama
watch(() => selectedDates.value, (newVal) => {
  if (JSON.stringify(newVal) !== JSON.stringify(selected.value)) initializeData()
}, { deep: true })

// Verileri sunucudan çekme
const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.scheduleSettings, { params: { assetId: store.state.assetId } })
    selectedDates.value = response.data.data.scheduling
    automaticPublishing.value = response.data.data.automaticPublishing
    initializeData()
  } catch (error) {
    responseHandlerService.handleError('An error occurred')
    console.error(error)
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

// Verileri sunucuya kaydetme
const save = async () => {
  emit('saving', true)
  await axios.put(settings.scheduleSettings, {
    scheduling: selectedDates.value,
    automaticPublishing: automaticPublishing.value,
    assetId: store.state.assetId,
  }).then(() => {
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
    store.dispatch('setAutomaticPublishing', automaticPublishing.value)
  }).catch(error => {
    responseHandlerService.handleError('An error occurred')
    console.error(error)
  }).finally(() => emit('saving', false))
}

// Bileşenin yüklenmesi sırasında verileri çek
onMounted(async () => {
  await fetch()
})

// Bileşen fonksiyonları dışa aktar
defineExpose({
  save,
})
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}
</style>