<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{ t('assets.components.create.components.introduction.information.titles.assetInformation') }} </h2>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center w-full">
        <customInput :label="t('assets.components.create.components.introduction.information.labels.assetName')" :description="t('assets.components.create.components.introduction.information.descriptions.enterAssetName')" v-model="list.name" :required="true" :loading="inputLoading" container-class="w-full"/>
        <customInput :label="t('assets.components.create.components.introduction.information.labels.website')" :description="t('assets.components.create.components.introduction.information.descriptions.enterWebsite')" v-model="list.website" :required="true" :loading="inputLoading" container-class="w-full"/>
      </div>
      <div class="flex flex-col space-y-4 w-full">
        <language-selector v-model="list.languageId" :label="t('assets.components.create.components.introduction.information.labels.languageSelection')" :description="t('assets.components.create.components.introduction.information.descriptions.selectLanguage')"/>
      </div>
    </div>
    <div class="bg-white p-4 rounded-lg shadow-sm">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{ t('assets.components.create.components.introduction.information.titles.searchEngines') }} </h2>
      <div class="flex flex-col space-y-4 w-full">
        <engine-location-selector v-model="list.searchEngineLocations"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import customInput from '@/components/inputs/customInput.vue'
import LanguageSelector from '@/components/inputs/languageSelector.vue'
import EngineLocationSelector from '@/components/engineLocationSelector/index.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const inputLoading = computed(() => store.state.assetData.inputLoading)
const list = computed(() => store.state.assetData.introductionList)
const assets = computed(() => store.state.assets)

const isValid = () => {
  return list.value.name && list.value.website && list.value.languageId
}

const finalize = () => {
  if (route.params.id) {
    if (isValid()) return true

    store.dispatch('handleError', {
      message: t('assets.components.create.components.introduction.information.errors.fillAllFields'),
    })
  } else {
    const nameExists = assets.value.some((asset) => asset.name.trim().toLowerCase() === list.value.name.trim()
        .toLowerCase())

    if (isValid() && !nameExists) return true

    store.commit('setSnackbarPosition', { top: true, left: true })

    if (nameExists) {
      store.dispatch('handleError', {
        message: t('assets.components.create.components.introduction.information.errors.duplicateAssetName'),
      })
    } else {
      store.dispatch('handleError', {
        message: t('assets.components.create.components.introduction.information.errors.fillAllFields'),
      })
    }
  }
  return false
}

defineExpose({
  finalize,
})
</script>