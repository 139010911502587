<template>
  <div class="bg-white rounded p-4 relative">
    <h2 class="text-sm font-semibold mb-1 text-gray-800">{{ t('globalComponents.engineLocationSelector.title') }}</h2>
    <h2 class="text-xs text-gray-500 mb-2">{{ t('globalComponents.engineLocationSelector.description') }}</h2>

    <div class="flex flex-wrap gap-2 mb-3">
      <button v-for="engine in engines" :key="engine.id" @click="selectEngine(engine)" class="flex items-center gap-2 px-3 py-2 rounded-md transition-colors duration-200 hover:bg-gray-200 border-lobster-50 border" :class="{ 'bg-gray-200': selectedEngine === engine }">
        <img :src="engine.icon" :alt="engine.name" class="w-5 h-5"> <span class="text-sm font-medium">{{
          engine.name
        }}</span> <span v-if="getCurrentFilteredLocationCount(engine.name) > 0" class="bg-primary text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
          {{ getCurrentFilteredLocationCount(engine.name) }}
        </span>
      </button>
    </div>

    <div v-if="selectedEngine" class="space-y-3">
      <custom-input v-model="searchQuery" @input="debouncedFetchLocations" :placeholder="inputPlaceholder" icon="search" :show-icon="true" class="custom-input"/>

      <teleport to="body">
        <div v-if="searchQuery || isLoading" class="fixed z-50 border rounded shadow-lg bg-white max-h-48 overflow-y-auto text-sm" :style="dropdownStyle">
          <div v-if="isLoading" class="p-3 text-sm text-gray-500 flex items-center">
            <span class="material-symbols-outlined animate-spin mr-2 !text-sm">progress_activity</span> {{ t('globalComponents.engineLocationSelector.loading') }}
          </div>
          <div v-else-if="searchQuery && options.length === 0" class="p-3 text-gray-500">
            {{ t('globalComponents.engineLocationSelector.noResults') }}
          </div>
          <button v-else v-for="option in options" :key="option.id" @click="toggleSelection(option)" class="w-full text-left p-2 hover:bg-gray-50 flex items-center justify-between" :class="{'bg-green-50': isSelected(option)}">
            <span class="text-sm">{{ getDisplayName(option) }}</span> <span v-if="isSelected(option)" class="text-green-600 text-xs">
              {{ t('globalComponents.engineLocationSelector.selected') }}
            </span>
          </button>
        </div>
      </teleport>

      <div class="min-h-14">
        <div v-if="currentSelectedLocations.length > 0" class="h-full">
          <p class="text-sm font-medium mb-2">
            {{ currentSelectedLocations.length }} {{
              t('globalComponents.engineLocationSelector.selectedLocations')
            }}: </p>
          <div class="flex flex-wrap gap-2">
            <button v-for="location in currentSelectedLocations" :key="location.id" @click="toggleSelection(location)" class="bg-gray-100 text-sm px-2 py-1 rounded-full flex items-center hover:bg-gray-200">
              <span class="mr-1">{{ getDisplayName(location) }}</span> <span class="material-symbols-outlined !text-[20px]">
            close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { debounce } from 'lodash'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CustomInput from '@/components/inputs/customInput.vue'

const props = defineProps({
  modelValue: [String, Number, Object, Array],
  stateMode: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const store = useStore()

const searchQuery = ref('')
const engines = ref([
  { id: 1, name: 'Yandex', icon: require('@/assets/icons/yandex.svg') },
  { id: 2, name: 'Google', icon: require('@/assets/icons/google.svg') },
  { id: 3, name: 'Bing', icon: require('@/assets/icons/bing.svg') },
])
const selectedEngine = ref(null)
const isLoading = ref(false)
const selectedLocations = ref({ google: [], bing: [], yandex: [] })
const options = ref([])
const hoverState = ref({})

const dropdownStyle = ref({
  top: '0px',
  left: '0px',
  width: '300px',
})

const currentSelectedLocations = computed(() => {
  if (selectedEngine.value) {
    const engineNameLower = selectedEngine.value.name.toLowerCase()
    return selectedLocations.value[engineNameLower]
        .filter(location => location.state !== 3)
        .map(location => ({ ...location, hover: location.hover || false }))
  }
  return []
})

const inputPlaceholder = computed(() => {
  return options.value.length === 0 && searchQuery.value
      ? t('globalComponents.engineLocationSelector.noResults')
      : t('globalComponents.engineLocationSelector.inputPlaceholder')
})

const fetchLocations = async (engine, query) => {
  try {
    isLoading.value = true
    const res = await axios.get(assets.searchEngineLocation, {
      params: { searchEngineId: engine.id, search: query || '', assetId: store.state.assetId },
    })
    const fetchedLocations = res.data.data.detail.map(location => ({
      id: location.id,
      locationId: location.locationId,
      name: location.name,
      engineId: engine.id,
      canonicalName: location.canonicalName,
    }))

    const engineNameLower = engine.name.toLowerCase()
    const selectedEngineLocations = selectedLocations.value[engineNameLower]
    options.value = fetchedLocations.filter(
        location => !selectedEngineLocations.some(l => l.id === location.id && (l.state === 1 || l.state === 2)),
    )
  } catch (error) {
    responseHandlerService.errorHandler(t('globalComponents.engineLocationSelector.fetchError'))
  } finally {
    isLoading.value = false
  }
}

const debouncedFetchLocations = debounce(() => {
  if (selectedEngine.value) {
    fetchLocations(selectedEngine.value, searchQuery.value)
  }
}, 300)

const selectEngine = (engine) => {
  selectedEngine.value = engine
  fetchLocations(engine, '')
}

const toggleSelection = (option) => {
  const engineNameLower = selectedEngine.value.name.toLowerCase()
  const selectedEngineLocations = selectedLocations.value[engineNameLower]

  const index = selectedEngineLocations.findIndex(loc => {
    if (selectedEngine.value.name === 'Yandex') return loc.name === option.name
    else return loc.name === option.name || loc.canonicalName === option.canonicalName
  })

  if (props.stateMode) {
    if (index !== -1) {
      if (selectedEngineLocations[index].state === 1 || selectedEngineLocations[index].state === 2) {
        selectedEngineLocations[index].state = 3
        responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.deselectionSuccess'))
      } else if (selectedEngineLocations[index].state === 3) {
        selectedEngineLocations[index].state = 1
        responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.reselectionSuccess'))
        searchQuery.value = ''
      }
    } else {
      const activeSelections = selectedEngineLocations.filter(loc => loc.state === 1 || loc.state === 2)
      if (activeSelections.length >= 3) {
        responseHandlerService.warningHandler(t('globalComponents.engineLocationSelector.limitWarning'))
        return
      }
      selectedEngineLocations.push({ ...option, state: 2 })
      responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.selectionSuccess'))
      searchQuery.value = ''
    }

    selectedLocations.value[engineNameLower] = [...selectedEngineLocations]
    options.value = options.value.filter(loc => loc.id !== option.id)
    searchQuery.value = ''
    fetchLocations(selectedEngine.value, '')
  } else {
    if (index !== -1) {
      selectedEngineLocations.splice(index, 1)
      searchQuery.value = ''
      responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.deselectionSuccess'))
    } else {
      const activeSelections = selectedEngineLocations.filter(loc => loc.state === undefined)
      if (activeSelections.length >= 3) {
        responseHandlerService.warningHandler(t('globalComponents.engineLocationSelector.limitWarning'))
        return
      }
      selectedEngineLocations.push({ ...option })
      searchQuery.value = ''
      responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.selectionSuccess'))
    }
    selectedLocations.value[engineNameLower] = [...selectedEngineLocations]
  }
}

const deselectPerEngine = (engine) => {
  const engineNameLower = engine.name.toLowerCase()
  if (props.stateMode) {
    selectedLocations.value[engineNameLower] = selectedLocations.value[engineNameLower].map(location => ({
      ...location,
      state: 3,
    }))
  } else {
    selectedLocations.value[engineNameLower] = []
  }

  responseHandlerService.successHandler(t('globalComponents.engineLocationSelector.allLocationsDeselected', { engine: engine.name }))

  if (selectedEngine.value && selectedEngine.value.name === engine.name) {
    fetchLocations(engine, '')
  }
}

const buttonClass = (engine) => {
  return [
    'flex items-center space-x-2 rounded-lg shadow-sm p-2 cursor-pointer relative hover:bg-gray-100 transition-colors duration-200',
    { 'bg-gray-100': selectedEngine.value === engine },
  ]
}

const getDisplayName = (option) => {
  return selectedEngine.value.name === 'Yandex' ? option.name : option.canonicalName || option.name
}

const getCurrentFilteredLocationCount = (engineName) => {
  return selectedLocations.value[engineName.toLowerCase()].filter(location => location.state !== 3).length
}

const isSelected = (option) => {
  if (selectedEngine.value) {
    const engineNameLower = selectedEngine.value.name.toLowerCase()
    return selectedLocations.value[engineNameLower].some(loc => {
      if (selectedEngine.value.name === 'Yandex') return loc.name === option.name && (loc.state === 1 || loc.state === 2)
      return loc.canonicalName === option.canonicalName && (loc.state === 1 || loc.state === 2)
    })
  }
  return false
}

const updateDropdownPosition = () => {
  const input = document.querySelector('.custom-input')
  if (input) {
    const rect = input.getBoundingClientRect()
    dropdownStyle.value.top = `${rect.bottom + window.scrollY}px`
    dropdownStyle.value.left = `${rect.left + window.scrollX}px`
    dropdownStyle.value.width = `${rect.width}px`
  }
}

onMounted(() => {
  window.addEventListener('resize', updateDropdownPosition)
  window.addEventListener('scroll', updateDropdownPosition)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateDropdownPosition)
  window.removeEventListener('scroll', updateDropdownPosition)
})

watch([searchQuery, isLoading], () => {
  nextTick(updateDropdownPosition)
})

watch(() => props.modelValue, (newVal) => {
  selectedLocations.value = newVal
}, { immediate: true })

watch(selectedLocations, (newVal) => {
  emit('update:modelValue', newVal)
}, { deep: true })
</script>
