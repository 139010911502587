<template>
  <VueFinalModal :click-to-close="!loading" :esc-to-close="!loading" @opened="opened" @closed="closed" class="flex justify-center items-center" content-class="flex flex-col max-w-xl bg-white rounded-lg shadow-lg w-full md:w-[550px]" contentTransition="vfm-fade">
    <div class="h-full flex flex-col w-full">
      <div class="w-full flex items-center justify-between bg-primary bg-opacity-90 text-white p-2 rounded-t-lg">
        <h2 class="text-sm font-medium flex items-center">
          <span class="material-symbols-outlined !text-[22px] mr-2">smart_toy</span>
          {{ isTrendContent ? t('entity.components.newContent.components.newContentModal.createTrendContentTitle') : t('entity.components.newContent.components.newContentModal.createContentTitle') }}
        </h2>
        <button @click="handleClose" class="text-white hover:text-gray-200 transition-colors flex items-center">
          <span class="material-symbols-outlined !text-[22px]">close</span>
        </button>
      </div>

      <div class="flex flex-col space-y-4 w-full p-4 max-h-[64vh] overflow-auto thin-scrollbar">
        <!-- Trend içerik bilgileri -->
        <div v-if="isTrendContent" class="bg-white border-b border-gray-200 pb-2">
          <h3 class="text-lg font-semibold text-gray-800 mb-3 flex items-center">
            <span class="material-symbols-outlined !text-[20px] mr-2 text-primary">trending_up</span> {{ t('entity.components.newContent.components.newContentModal.trendContentInfo') }} </h3>
          <div class="space-y-2">
            <div class="flex items-start">
              <span class="material-symbols-outlined !text-[20px] text-gray-500 mr-2">key</span>
              <div>
                <p class="text-sm font-medium text-gray-700 flex items-center">
                  {{ t('entity.components.newContent.components.newContentModal.trendKeyword') }} </p>
                <p class="text-sm text-gray-600">{{ trendKeyword }}</p>
              </div>
            </div>
            <div class="flex items-start">
              <span class="material-symbols-outlined !text-[20px] text-gray-500 mr-2">link</span>
              <div>
                <p class="text-sm font-medium text-gray-700 flex items-center">
                  {{ t('entity.components.newContent.components.newContentModal.trendUrl') }} </p>
                <a :href="trendUrl" target="_blank" rel="noopener noreferrer" class="text-sm text-blue-600 hover:underline break-all">{{ extractDomain(trendUrl) }}</a>
              </div>
            </div>
          </div>
        </div>

        <LanguageSelector v-model="languageId" :label="t('entity.components.newContent.components.newContentModal.languageSelectionLabel')" :description="t('entity.components.newContent.components.newContentModal.languageSelectionDescription')"/>

        <!-- Normal içerik oluşturma alanları -->
        <template v-if="!isTrendContent">
          <CustomInput v-model="assistiveDescription" type="textarea" :label="t('entity.components.newContent.components.newContentModal.contentNoteLabel')" :description="t('entity.components.newContent.components.newContentModal.contentNoteDescription')" containerClass="w-full"/>

          <CustomInput v-model="assistiveImageDescription" type="textarea" :label="t('entity.components.newContent.components.newContentModal.imageNoteLabel')" :description="t('entity.components.newContent.components.newContentModal.imageNoteDescription')" containerClass="w-full"/>

          <TagInput :label="t('entity.components.newContent.components.newContentModal.keywordsLabel')" v-model="keywords" :secondary="true" containerClass="w-full" :description="t('entity.components.newContent.components.newContentModal.keywordsDescription')" :isStateMode="false" :maxCount="52"/>
        </template>
      </div>

      <div class="p-3 bg-gray-50 rounded-b-lg">
        <CustomButton v-if="isTrendContent" @click="handleCancel" :buttonText="t('entity.components.newContent.components.newContentModal.cancelButton')" additionalButtonClass="!bg-red-400 hover:!bg-red-500" :fullWidth="true" icon="cancel" size="sm"/>
        <CustomButton v-else @click="handleSubmit" :buttonText="loading ? t('entity.components.newContent.components.newContentModal.cancelButton') : t('entity.components.newContent.components.newContentModal.createButton')" :additionalButtonClass="loading ? '!bg-red-400 hover:!bg-red-500' : '!bg-primary hover:!bg-primary-dark'" :fullWidth="true" :icon="loading ? 'cancel' : 'auto_awesome'" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import CustomInput from '@/components/inputs/customInput.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import TagInput from '@/components/inputs/tagInput.vue'
import LanguageSelector from '@/components/inputs/languageSelector.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const store = useStore()
const { t } = useI18n()
const emit = defineEmits(['response', 'opened', 'contentLoading', 'tags', 'update:modelValue', 'reset'])

const props = defineProps({
  isTrendContent: {
    type: Boolean,
    default: false,
  },
  trendKeyword: {
    type: String,
    default: '',
  },
  trendUrl: {
    type: String,
    default: '',
  },
})

const languageId = ref(store.state.assetLanguage || 1)
const assistiveDescription = ref('')
const assistiveImageDescription = ref('')
const keywords = ref([])
const loading = ref(false)
let cancelTokenSource = ref(null)

watch(() => props.isTrendContent, (newValue) => {
  if (newValue) {
    createTrendContent()
  }
}, { immediate: true })

const handleSubmit = () => {
  if (loading.value) cancelRequest()
  else createContent()
}

const createContent = () => {
  if (keywords.value.length < 3) {
    responseHandlerService.handleWarning(t('entity.components.newContent.components.newContentModal.responseHandlers.minKeywordsWarning'))
    return
  }

  loading.value = true
  emit('contentLoading', true)
  cancelTokenSource.value = axios.CancelToken.source()

  axios.post(entity.createContent, {
    languageId: languageId.value,
    keywords: keywords.value,
    assistiveDescription: assistiveDescription.value,
    assistiveImageDescription: assistiveImageDescription.value,
    assetId: store.state.assetId,
  }, {
    cancelToken: cancelTokenSource.value.token,
  })
      .then((res) => {
        responseHandlerService.handleSuccess(t('entity.components.newContent.components.newContentModal.responseHandlers.successCreate'))
        emit('response', res.data.data)
        emit('tags', keywords.value)
        closeModal()
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled:', err.message)
          responseHandlerService.handleWarning(t('common.requestCancelled'))
        } else {
          console.log(err)
          responseHandlerService.handleError(t('entity.components.newContent.components.newContentModal.responseHandlers.errorCreate'))
        }
      })
      .finally(() => {
        loading.value = false
        emit('contentLoading', false)
        cancelTokenSource.value = null
      })
}

const createTrendContent = () => {
  loading.value = true
  emit('contentLoading', true)
  cancelTokenSource.value = axios.CancelToken.source()

  axios.post(entity.createTrendContent, {
    languageId: languageId.value,
    keyword: props.trendKeyword,
    url: props.trendUrl,
    assetId: store.state.assetId,
  }, {
    cancelToken: cancelTokenSource.value.token,
  })
      .then((res) => {
        responseHandlerService.handleSuccess(t('entity.components.newContent.components.newContentModal.responseHandlers.successCreateTrend'))
        emit('response', res.data.data)
        closeModal()
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled:', err.message)
          responseHandlerService.handleWarning(t('common.requestCancelled'))
        } else {
          console.log(err)
          responseHandlerService.handleError(t('entity.components.newContent.components.newContentModal.responseHandlers.errorCreateTrend'))
        }
      })
      .finally(() => {
        loading.value = false
        emit('contentLoading', false)
        cancelTokenSource.value = null
      })
}

const cancelRequest = () => {
  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel('Operation canceled by the user.')
  }
}

const handleCancel = () => {
  cancelRequest()
  emit('reset')
  closeModal()
}

const opened = () => {
  emit('opened')
}

const closed = () => {
  emit('update:modelValue', false)
}

const closeModal = () => {
  emit('update:modelValue', false)
}

const handleClose = () => {
  if (loading.value) {
    responseHandlerService.handleWarning(t('entity.components.newContent.components.newContentModal.responseHandlers.operationInProgressWarning'))
  } else {
    if (props.isTrendContent) {
      handleCancel()
    } else {
      closeModal()
    }
  }
}

const extractDomain = (url) => {
  try {
    return new URL(url).hostname.replace('www.', '')
  } catch {
    return url
  }
}
</script>