<template>
  <div class="flex h-screen">
    <info/>
    <AuthForm/>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onMounted } from 'vue'
import Info from './components/information.vue'
import AuthForm from './components/authForm.vue'

const store = useStore()
const router = useRouter()

const token = computed(() => store.state.userData.token)

onMounted(() => {
  if (token.value) {
    router.push('/assets')
  }
})
</script>