<template>
  <div class="component-layout">
    <div class="flex flex-col space-y-4 w-full">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <IntegrationCard v-for="integration in integrations" :key="integration.id" :integration="integration" @toggle="toggleIntegration"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import IntegrationCard from './components/integrationCard.vue'
import axios from '@/plugins/axiosInstance'
import { settings, socialMedia } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  platforms: {
    type: Array,
    required: true,
  },
})

const route = useRoute()
const store = useStore()
const router = useRouter()
const { t } = useI18n()

let hasCheckedIntegration = false

const IntegrationEnums = {
  INSTAGRAM: 1,
  FACEBOOK: 2,
  X: 3,
  LINKEDIN: 4,
}

const integrationConfig = {
  [IntegrationEnums.INSTAGRAM]: {
    name: 'Instagram',
    icon: require('@/assets/icons/instagram.svg'),
    description: t('socialMedia.components.integrations.instagram.description'),
  },
  [IntegrationEnums.FACEBOOK]: {
    name: 'Facebook',
    icon: require('@/assets/icons/facebook.svg'),
    description: t('socialMedia.components.integrations.facebook.description'),
  },
  [IntegrationEnums.X]: {
    name: 'X',
    icon: require('@/assets/icons/twitterx.svg'),
    description: t('socialMedia.components.integrations.x.description'),
  },
}

const integrations = ref(
    Object.entries(integrationConfig).map(([id, config]) => ({
      id: Number(id),
      name: config.name,
      icon: config.icon,
      description: config.description,
      connected: false,
      loading: false,
    })),
)

const updateIntegrationsStatus = () => {
  if (Array.isArray(props.platforms)) {
    integrations.value.forEach(integration => {
      const platform = props.platforms.find(p => p.platformId === integration.id)
      if (platform && platform.authenticated) {
        integration.connected = true
      }
    })
  } else {
    console.error('props.platforms is not an array:', props.platforms)
  }
}

const toggleIntegration = async (id) => {
  const integration = integrations.value.find(i => i.id === id)
  if (integration) {
    try {
      integration.loading = true

      if (!integration.connected) {
        const redirectURL = `${window.location.origin}/settings?integration=${id}&tab=integrations`
        const response = await axios.post(socialMedia.socialAuth, {
          redirectURL: redirectURL,
          platformId: id,
        })
        if (response.data.data) {
          setTimeout(() => {
            window.location.href = response.data.data
            integration.loading = false
          }, 1000)
        } else {
          responseHandlerService.handleError(t('socialMedia.components.integrations.responseHandler.errors.authenticationProcess'))
          integration.loading = false
        }
      } else {
        const response = await axios.post(socialMedia.disconnect, { platformId: id })
        if (response.data.success) {
          integration.connected = false
          responseHandlerService.handleSuccess(t('settings.components.integrations.responseHandler.success.disconnectionSuccessful', { integrationName: integrationConfig[id].name }))
        } else responseHandlerService.handleError(t('settings.components.integrations.responseHandler.errors.disconnectionError', { integrationName: integrationConfig[id].name }))

        integration.loading = false
      }
    } catch (error) {
      console.error('Error:', error)
      responseHandlerService.handleError(t('socialMedia.components.integrations.responseHandler.errors.toggleIntegration'))
      integration.loading = false
    }
  }
}

const handleOAuthResponse = async (id, code) => {
  const redirectURL = `${window.location.origin}/settings?integration=${id}&tab=integrations`

  const integration = integrations.value.find(i => i.id === Number(id))
  if (integration) integration.loading = true

  await axios.post(socialMedia.socialOauth, {
    code: code, redirectURL, assetId: store.state.assetId, platformId: id,
  }).then((res) => {
    if (integration) integration.connected = true
    responseHandlerService.handleSuccess(t('settings.components.integrations.responseHandler.success.integrationSuccessful', { integrationName: integrationConfig[id].name }))
  }).catch((error) => {
    console.error('OAuth Error:', error)
    responseHandlerService.handleError(t('settings.components.integrations.responseHandler.errors.integrationError', { integrationName: integrationConfig[id].name }))
  }).finally(() => {
    if (integration) integration.loading = false
    router.replace({ query: { ...route.query, integration: undefined, code: undefined } })
  })
}

const checkPendingIntegration = () => {
  if (hasCheckedIntegration) return
  let { code, integration, tab } = route.query

  if (code && integration && tab) {
    handleOAuthResponse(Number(integration), code)
    hasCheckedIntegration = true
  }
}

onMounted(() => {
  checkPendingIntegration()
  updateIntegrationsStatus()
})
</script>