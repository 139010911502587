<template>
  <tooltip :title="tooltipText" :display-condition="localShowTooltip" :color="tooltipColor" :variation="tooltipVariation" :full-width="fullWidth" :container-class="containerClass">
    <template #content>
      <button :type="type" :class="buttonClasses" :disabled="isDisabled || isLoading" @click="handleClick" @mouseenter="localShowTooltip = true" @mouseleave="localShowTooltip = false">
        <span :class="contentClasses">
          <icon-component v-if="shouldShowLeftIcon" :icon="icon" :class="[iconSizeClass, iconClass]"/>
          <span v-if="isTextShowable" :class="textClasses">
            {{ isLoading ? computedLoadingText : buttonText }}
          </span>
          <icon-component v-if="shouldShowRightIcon" :icon="icon" :class="[iconSizeClass, iconClass]"/>
          <span v-if="isLoading" class="material-symbols-outlined animate-spin" :class="loadingIconClasses">
            progress_activity
          </span>
        </span>
      </button>
    </template>
  </tooltip>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import IconComponent from '@/components/general/iconComponent.vue'
import Tooltip from '@/components/general/tooltip.vue'

const { t } = useI18n()

const props = defineProps({
  type: { type: String, default: 'button' },
  buttonText: { type: String, default: 'Button' },
  loadingText: { type: String, default: '' },
  isLoading: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  icon: { type: String, default: '' },
  iconPosition: { type: String, default: 'left', validator: (value) => ['left', 'right'].includes(value) },
  iconClass: { type: String, default: '' },
  isTextShowable: { type: Boolean, default: true },
  color: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'accent', 'header', 'indigo', 'neutral'].includes(value),
  },
  variation: {
    type: String,
    default: 'solid',
    validator: (value) => ['solid', 'outline', 'rounded-full'].includes(value),
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  fullWidth: { type: Boolean, default: false },
  showTooltip: { type: Boolean, default: false },
  tooltipText: { type: String, default: '' },
  tooltipColor: { type: String, default: 'default' },
  tooltipVariation: { type: String, default: 'solid' },
  containerClass: { type: String, default: '' },
  showIcon: { type: Boolean, default: false },
  isIconSpaced: { type: Boolean, default: false },
})

const emit = defineEmits(['click'])

const localShowTooltip = ref(props.showTooltip)

const computedLoadingText = computed(() => props.loadingText || t('general.loading'))

const colorVariations = {
  primary: {
    solid: 'bg-primary hover:bg-primary text-white border border-primary hover:border-primary/90 rounded-md focus:ring-primary/50',
    outline: 'bg-white hover:bg-primary-50 border border-primary text-primary hover:text-primary-700 hover:border-primary-700 rounded-md focus:ring-primary/50',
    'rounded-full': 'bg-primary hover:bg-primary-700 text-white border border-primary hover:border-primary-700 rounded-full focus:ring-primary/50',
  },
  secondary: {
    solid: 'bg-secondary hover:bg-secondary-700 text-white border border-secondary hover:border-secondary-700 rounded-md focus:ring-secondary/50',
    outline: 'bg-white hover:bg-secondary-50 border border-secondary text-secondary hover:text-secondary-700 hover:border-secondary-700 rounded-md focus:ring-secondary/50',
    'rounded-full': 'bg-secondary hover:bg-secondary-700 text-white border border-secondary hover:border-secondary-700 rounded-full focus:ring-secondary/50',
  },
  accent: {
    solid: 'bg-accent hover:bg-accent-700 text-white border border-accent hover:border-accent-700 rounded-md focus:ring-accent/50',
    outline: 'bg-white hover:bg-accent-50 border border-accent text-accent hover:text-accent-700 hover:border-accent-700 rounded-md focus:ring-accent/50',
    'rounded-full': 'bg-accent hover:bg-accent-700 text-white border border-accent hover:border-accent-700 rounded-full focus:ring-accent/50',
  },
  header: {
    solid: 'bg-white hover:bg-white text-primary hover:text-primary/90 border border-white hover:border-white rounded-md focus:ring-primary/50',
    outline: 'bg-white hover:bg-white border border-white text-white hover:text-white hover:border-white rounded-md focus:ring-white/50',
    'rounded-full': 'bg-white hover:bg-white text-white hover:text-white border border-white hover:border-white rounded-full focus:ring-white/50',
  },
  indigo: {
    solid: 'bg-indigo hover:bg-indigo-700 text-white border border-indigo hover:border-indigo-700 rounded-md focus:ring-indigo/50',
    outline: 'bg-white hover:bg-indigo-50 border border-indigo text-indigo hover:text-indigo-700 hover:border-indigo-700 rounded-md focus:ring-indigo/50',
    'rounded-full': 'bg-indigo hover:bg-indigo-700 text-white border border-indigo hover:border-indigo-700 rounded-full focus:ring-indigo/50',
  },
  neutral: {
    solid: 'bg-neutral hover:bg-neutral-200 text-gray-800 border border-neutral hover:border-neutral-300 rounded-md focus:ring-neutral/50',
    outline: 'bg-white hover:bg-neutral-100 border border-neutral text-gray-800 hover:text-gray-900 hover:border-neutral-400 rounded-md focus:ring-neutral/50',
    'rounded-full': 'bg-neutral hover:bg-neutral-200 text-gray-800 border border-neutral hover:border-neutral-300 rounded-full focus:ring-neutral/50',
  },
}

const sizeClasses = {
  sm: 'px-2 py-1 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const iconSizeClass = computed(() => {
  const sizes = { sm: '!text-[16px]', md: '!text-base', lg: '!text-lg' }
  return sizes[props.size]
})

const loadingIconClasses = computed(() => {
  const sizes = {
    sm: '!text-xs',
    md: '!text-sm',
    lg: '!text-base',
  }
  return [sizes[props.size], 'loading-icon']
})

const shouldShowLeftIcon = computed(() =>
    props.showIcon && props.icon && props.iconPosition === 'left',
)

const shouldShowRightIcon = computed(() =>
    props.showIcon && props.icon && props.iconPosition === 'right',
)

const contentClasses = computed(() => {
  if (props.fullWidth) {
    if (props.isIconSpaced && (shouldShowLeftIcon.value || shouldShowRightIcon.value)) {
      return ['flex items-center w-full justify-between']
    }
    return ['flex items-center justify-center w-full']
  }
  return ['flex items-center justify-center space-x-1']
})

const textClasses = computed(() => [
  'truncate',
  {
    'flex-grow': props.fullWidth,
    'text-left': props.fullWidth && props.isIconSpaced && shouldShowRightIcon.value,
    'text-right': props.fullWidth && props.isIconSpaced && shouldShowLeftIcon.value,
    'text-center': !props.isIconSpaced || !props.fullWidth || (!shouldShowLeftIcon.value && !shouldShowRightIcon.value),
    'mx-2': props.fullWidth && props.isIconSpaced && (shouldShowLeftIcon.value || shouldShowRightIcon.value),
  },
])

const buttonClasses = computed(() => [
  sizeClasses[props.size],
  'font-semibold',
  'transition-colors duration-300',
  'focus:outline-none focus:ring-2 focus:ring-offset-2',
  colorVariations[props.color][props.variation],
  props.isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
  props.fullWidth ? 'w-full' : 'inline-flex',
  props.isTextShowable ? 'min-w-[100px]' : '',
  'items-center justify-center', // Added justify-center here
  !props.fullWidth && 'max-w-[200px]',
])

const handleClick = () => {
  if (!props.isLoading && !props.isDisabled) emit('click')
}
</script>